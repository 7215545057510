import './bootstrap';
import Swal from 'sweetalert2';
import AOS from 'aos';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'


try {
    window.Swal = Swal;
    window.AOS = AOS;
    window.lightGallery = lightGallery;
    window.lgThumbnail = lgThumbnail;
    window.lgZoom = lgZoom;

    require('isotope-layout/dist/isotope.pkgd');
    require('slick-carousel-latest/slick/slick');
} catch (err) {
    console.log(err);
}
